import React, { useRef, useContext, useState } from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { AppState } from "../../../App";
import axios from "../../../axiosConfig";
import { useNavigate } from "react-router-dom";
import classes from "./AskQuestionPage.module.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

function AskQuestionPage() {
  const [titleError, setTitleError] = useState(false);
  const [detailError, setDetailError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useContext(AppState);
  const userid = user.userid;
  const navigate = useNavigate();
  const titleDom = useRef();
  const detailDom = useRef();

  async function handle(e) {
    e.preventDefault();
    const titleValue = titleDom?.current?.value;
    const detailValue = detailDom?.current?.value;

    // Reset errors

    setTitleError(!titleValue);
    setDetailError(!detailValue);
    if (!titleValue || !detailValue) {
      return;
    }
    // if (!titleValue || !detailValue) {
    //   alert("Please provide all required information");
    //   return;
    // }
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await axios.post(
        "/question",
        { userid: userid, title: titleValue, description: detailValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // alert("Question asked successfully");

      // navigate("/home");

      setIsSuccess(true);
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } catch (error) {
      alert("Something went wrong");
      console.error(error.response ? error.response.data : error.message);
    }
  }

  return (
    <>
      <Header />
      <section className={classes.back}>
        <div className={classes.ques}>
          <div>
            <h3 className={classes.step}>Steps To Write A Good Question</h3>
            <div className={`${classes.dash} ${classes.under}`}></div>
          </div>
          <div className={classes.points}>
            <p>
              <BsArrowRightCircleFill className={classes.icon} />
              Summarize your problems in a one-line title.
            </p>
            <p>
              <BsArrowRightCircleFill className={classes.icon} />
              Describe your problem in more detail
            </p>
            <p>
              <BsArrowRightCircleFill className={classes.icon} />
              Describe what you tried and what you expected to happen.
            </p>
            <p>
              <BsArrowRightCircleFill className={classes.icon} />
              Review your question and post it here.
            </p>
          </div>
        </div>
        <div className={classes.question_container}>
          <h3 className={classes.post}>Post Your Question</h3>
          <form onSubmit={handle}>
            {isSuccess && (
              <p className={classes.redirect}>
                Question posted successfully. Redirecting to home page .....
              </p>
            )}
            <input
              type="text"
              placeholder="Question title"
              ref={titleDom}
              className={`${classes.title} ${
                titleError ? classes.errorTitle : ""
              }`}
            />
            <br />
            <input
              type="text"
              placeholder="Question detail..."
              ref={detailDom}
              className={`${classes.detail} ${
                detailError ? classes.errorTitle : ""
              }`}
            />
            <div className={classes.buttonContainer}>
              <button className={classes.sub}>Post Question</button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AskQuestionPage;
