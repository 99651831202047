import React, { useEffect, useState, useCallback } from "react";
//imports the useContext hook from React to access the context values
import { useContext } from "react";
//Imports the AppState context created in the App component
import { AppState } from "../../App";

import { debounce } from "lodash";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import questionerIMG from "../../assets/user 1.png";
import { IoIosArrowForward } from "react-icons/io";
import classes from "./Home.module.css";
import Header from "../Header/Header";
//The Home component displays a welcome message to the user using the username value from the user object.It uses the useContext hook to access the user object from the AppState context. This object contains information about the logged-in user, such as their username.

function Home() {
  //provides the user object from the global state, which contains information about the logged-in user.
  const { user, isLoggedIn, logout } = useContext(AppState);
  const [questions, setQuestions] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const fetchQuestionsDebounced = useCallback(
    debounce(async (term) => {
      const token = localStorage.getItem("token");
      try {
        const { data } = await axios.get(
          term ? `/search?q=${term}` : "/question",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (Array.isArray(data.questions)) {
          setQuestions(data.questions);
        } else {
          console.error("Expected an array but got:", data);
        }
      } catch (error) {
        console.error(
          "Error fetching questions:",
          error.response?.data || error.message
        );
      }
    }, 300), // Debounce delay (300ms)
    []
  );

  // Effect to fetch questions initially
  useEffect(() => {
    fetchQuestionsDebounced("");
  }, [fetchQuestionsDebounced]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    fetchQuestionsDebounced(value);
  };

  useEffect(() => {
    // Redirect to login page if the user is not logged in
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const handleQuestionClick = (questionId) => {
    navigate(`/question/${questionId}`);
  };
  // <h1>Questions</h1>;
  return (
    <>
      <Header />

      {isLoggedIn && (
        <section className={classes.questionsPage}>
          <div className={classes.welcomeMessage}>
            <button
              className={classes.askQuestionButton}
              onClick={() => navigate("/question")}
            >
              Ask Question
            </button>
            <h1>
              Wellcome:<span>{user.username || "Loading..."}</span>
            </h1>
          </div>

          <div className={classes.container}>
            <form action="" onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                placeholder="search question"
                className={classes.search}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </form>
            <hr className={classes.li} />
          </div>

          <div className={classes.questionsList}>
            <h2>Questions</h2>
            {Array.isArray(questions) && questions.length === 0 ? (
              <p>No questions available.</p>
            ) : (
              Array.isArray(questions) &&
              questions
                .slice() // Create a shallow copy to avoid mutating the original array
                .reverse() // Reverse the order of the array
                .map((question) => (
                  <div
                    key={question.question_id}
                    className={classes.questionItem}
                    onClick={() => handleQuestionClick(question.question_id)}
                  >
                    <div className={classes.questionerInfo}>
                      <div>
                        <img
                          src={questionerIMG}
                          alt={`${question.username}'s image`}
                          className={classes.questionerImage}
                          style={{ width: "80px", height: "80px" }}
                        />
                        <h5 className={classes.username}>
                          {question.username}
                        </h5>
                      </div>
                      <h3>{question.title}</h3>
                      <IoIosArrowForward className={classes.icon} />
                    </div>
                  </div>
                ))
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default Home;
